import pro1 from "../assets/project1.png"
import pro2 from "../assets/project2.png"
import pro3 from "../assets/project3.png"
import pro4 from "../assets/project4.jpg"
import pro5 from "../assets/project5.png"
import pro6 from "../assets/project6.png"
import pro7 from "../assets/project7.png"
import pro8 from "../assets/project8.png"
import pro9 from "../assets/project9.png"

const ProjectCardData =[
    {
        imgsrc : pro1,
        title : "Portfolio Website",
        text : "Created a portfolio website for myself and hosted it on my personal domain. Used ReactJs, JavaScript and tailwindCSS for developing this website.",
        view: "https://www.prateekprojects.online",
        source: "https://github.com/Prat05devs/react-one-portfolio"
    },
    {
        imgsrc : pro2,
        title : "ChatBot Website",
        text : " Designed a Chat bot website for a client with functionalities like send prompts as audio and videos along with text prompt.",
        view: "https://prat05devs.github.io/CustomChatBotApp/",
        source: "https://github.com/Prat05devs/CustomChatBotApp"
    },
    {
        imgsrc : pro3,
        title : "E-commerce Website",
        text : "Currently working on this one. trying to make a e-commerce website with all functionalities along with payment integration and maps.",
        view: "https://prat05devs.github.io/DapperClothing/",
        source: "https://github.com/Prat05devs/DapperClothing"
    },
    {
        imgsrc : pro4,
        title : "Portfolio Website",
        text : "Created this website as a portfolio, now I have deployed a new portfolio that you guys are currently watching. ",
        view: "https://prat05devs.github.io/oldPortfolioPrateek/",
        source: "https://github.com/Prat05devs/oldPortfolioPrateek"
    },
    {
        imgsrc : pro5,
        title : "Static Website for AWS Project",
        text : "Created this website as a sample for my AWS project WHere I deployed this website on a custom domain on AWS.",
        view: "https://prat05devs.github.io/SmallStaticWebsite/",
        source: "https://github.com/Prat05devs/SmallStaticWebsite"
    },
    {
        imgsrc : pro6,
        title : "Banking Website",
        text : "Created a e-banking website using PHP and hosted it thorough MAMP(XAMPP for windows), it maintains a proper backend record of customers.",
        view: "https://prat05devs.github.io/phpBankingWebsite/",
        source: "https://github.com/Prat05devs/phpBankingWebsite"
    },
    {
        imgsrc : pro7,
        title : "Real-Time Group Chat App",
        text : "Hommies 📞, a real-time group chat application built with WebSockets and Socket.io to enable dynamic, interactive communication between users.",
        view: "https://github.com/Prat05devs/WebSockets-ChatApp",
        source: "https://github.com/Prat05devs/WebSockets-ChatApp"
    },
    {
        imgsrc : pro8,
        title : "PDF Q&A Application",
        text : "Intelligent document analysis application allows users to upload PDF documents and ask questions about their content using natural language processing.",
        view: "https://github.com/Prat05devs/PDF_Q-A_Application",
        source: "https://github.com/Prat05devs/PDF_Q-A_Application"
    },
    {
        imgsrc : pro9,
        title : "Multi-Step Form with Real-Time Updates",
        text : "A modern, user-friendly multi-step form built with Next.js and TypeScript. Features dark mode, internationalization, and real-time validation.",
        view: "https://github.com/Prat05devs/Multi-Step_Form",
        source: "https://github.com/Prat05devs/Multi-Step_Form"
    }
]
export default ProjectCardData;